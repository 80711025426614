import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, isJson, parseJsonArr } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataMurid: [],
            DataPendidikan: [],
            DataPekerjaan: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/humas_api", { act: "data alumni", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/humas_api", { act: "detail alumni", ID: Detail.ID });
        if (sql.status == "sukses") {
            let DataPendidikan = parseJsonArr(sql.data.Pendidikan);
            let DataPekerjaan = parseJsonArr(sql.data.Pekerjaan);
            this.setState({ Detail: sql.data || Detail, DataPendidikan: DataPendidikan, DataPekerjaan: DataPekerjaan });
        }
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    handleModalCariMurid() {
        this.handleCariMurid("");
        openModal("modalCariMurid");
    }

    async handleCariMurid(val) {
        let sql = await api("controler/humas_api", { act: "data master murid", q: val }, true);
        if (sql.status == "sukses") this.setState({ DataMurid: sql.data });
    }

    handlePilihMurid(e) {
        let data = e.data;
        let Detail = this.state.Detail;
        Detail.MuridID = data.ID;
        Detail.Nama = data.NAMA;
        Detail.NISN = data.NISN;
        Detail.Email = data.EMAIL;
        Detail.Telp = data.NO_HANDPHONE;
        Detail.Alamat = data.ALAMAT;
        this.setState({ Detail: Detail });
        document.getElementById('btnTutupModalCariMurid').click();
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Alumni</div>
                            <div className="col-md-3 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "TahunLulus", cap: "Tahun Lulus" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah alumni" : "edit alumni"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Alumni" : "Edit Alumni"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun Lulus</label>
                                                <input type="date" className='form-control' id="edtTahunLulus" name="TahunLulus" value={this.state.Detail.TahunLulus || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TahunLulus = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan TahunLulus</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Siswa</label>
                                                <div className='input-group'>
                                                    <input type='hidden' name="MuridID" value={this.state.Detail.MuridID} />
                                                    <input type='hidden' name="NISN" value={this.state.Detail.NISN} />
                                                    <input type="text" className='form-control' id="edtNama" name="Nama" value={this.state.Detail.Nama || ""} onFocus={(e) => this.handleModalCariMurid()} readOnly />
                                                    <button type="button" className="btn btn-default" id="btnSearchMurid" onClick={(e) => this.handleModalCariMurid()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Siswa</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Email</label>
                                                <input type="text" className='form-control' id="Email" name="Email" value={this.state.Detail.Email || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Email = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Email</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Telp</label>
                                                <input type="text" className='form-control' id="Telp" name="Telp" value={this.state.Detail.Telp || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Telp = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Telp</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' id="Alamat" name="Alamat" value={this.state.Detail.Alamat || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Alamat = e.target.value;
                                            this.setState({ Detail: data });
                                        }} required />
                                        <div className='invalid-feedback'>Silahkan masukan Alamat</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' id="Keterangan" name="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Keterangan</div>
                                    </div>
                                    <p></p>
                                    <input type="hidden" name="Pendidikan" value={JSON.stringify(this.state.DataPendidikan)} />
                                    <input type="hidden" name="Pekerjaan" value={JSON.stringify(this.state.DataPekerjaan)} />
                                    <button type="button" className="btn btn-default" id="btnAddPendidikan" onClick={(e) => {
                                        let data = this.state.DataPendidikan;
                                        data.push({ Tahun: 2024, Nama: "", Ket: "" });
                                        this.setState({ DataPendidikan: data });
                                    }}>Tambah Pendidikan</button>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Tahun</th>
                                                    <th>Nama Kampus</th>
                                                    <th>Keterangan</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataPendidikan.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <input type='text' className='form-control' value={tr.Tahun} onChange={(e) => {
                                                                    let data = this.state.DataPendidikan;
                                                                    data[i].Tahun = e.target.value;
                                                                    this.setState({ DataPendidikan: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type='text' className='form-control' value={tr.Nama} onChange={(e) => {
                                                                    let data = this.state.DataPendidikan;
                                                                    data[i].Nama = e.target.value;
                                                                    this.setState({ DataPendidikan: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <input type='text' className='form-control' value={tr.Ket} onChange={(e) => {
                                                                    let data = this.state.DataPendidikan;
                                                                    data[i].Ket = e.target.value;
                                                                    this.setState({ DataPendidikan: data });
                                                                }} />
                                                            </td>
                                                            <td>
                                                                <button className='btn btn-danger' type='button' onClick={(e) => {
                                                                    let data = this.state.DataPendidikan;
                                                                    let temp = [];
                                                                    for (let dd in data) if (dd != i) temp.push(data[dd]);
                                                                    this.setState({ DataPendidikan: temp });
                                                                }}>
                                                                    <i className='fas fa-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariMurid" tabIndex="-1" aria-labelledby="modalCariMurid" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content shadow">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Murid</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataMurid}
                                    thead={[
                                        { cap: "NISN", sort: "NISN", type: "str" },
                                        { cap: "Nama", sort: "NAMA", type: "str" },
                                        { cap: "Telp", sort: "NO_HANDPHONE", type: "str" },
                                        { cap: "Email", sort: "EMAIL", type: "str" },
                                        { cap: "Alamat", sort: "ALAMAT", type: "str" },
                                    ]}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMurid'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus alumni" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Alumni</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;
