import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            IDSemester: "",
            IDKelas: "",
            DataKelas: [],
            DataGuru: [],
            DataMapel: [],
            DataJam: [],
            DataTahun: [],
            DataSemester: []
        };
    }

    async componentDidMount() {
        let sql = await api("controler/kurikulum_api", { act: "pendukung raport semester" });
        if (sql.status == "sukses") {
            this.setState({ DataSemester: sql.semester, DataKelas: sql.kelas });
            setTimeout(() => {
                this.handleMain();
            }, 1000);
        }
    }

    async handleMain() {
        let sql = await api("controler/kurikulum_api", { act: "data raport semester", q: this.state.q, IDSemester: this.state.IDSemester, IDKelas: this.state.IDKelas });
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/kurikulum_api", { act: "detail raport semester", ID: Detail.ID }, true);
        console.log(sql);
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataJam: sql.jam, DataGuru: sql.guru, DataKelas: sql.kelas, DataMapel: sql.mapel, DataTahun: sql.tahun });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-6 main-title">Raport Semester</div>
                            <div className="col-md-6 d-flex justify-content-end align-items-end gap-1">
                                <div className="input-group input-group-sm">
                                    <select className="form-select form-select-sm" value={this.state.IDSemester} onChange={(e) => this.setState({ IDSemester: e.target.value })} >
                                        <option value=''>Semester saat ini</option>
                                        {
                                            this.state.DataSemester.map((opt, i) => {
                                                return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                            })
                                        }
                                    </select>
                                    <select className="form-select form-select-sm" value={this.state.IDKelas} onChange={(e) => this.setState({ IDKelas: e.target.value })} >
                                        <option value=''>Semua Kelas</option>
                                        {
                                            this.state.DataKelas.map((opt, i) => {
                                                return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                            })
                                        }
                                    </select>
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Murid' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "NamaKelas", cap: "KELAS" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah jadwal ujian" : "edit jadwal ujian"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Jadwal Ujian" : "Edit Jadwal Ujian"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className="form-control" id="edtTanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jam</label>
                                                <select className='form-select' id="edtJam" name="Jam" value={this.state.Detail.Jam || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Jam = e.target.value;
                                                    let JamMulai = "07:00", JamSelesai = "09:00";
                                                    for (let dd of this.state.DataJam) {
                                                        if (dd.Nama == e.target.value) {
                                                            JamMulai = dd.JamMulai;
                                                            JamSelesai = dd.JamSelesai;
                                                        }
                                                    }
                                                    data.JamMulai = JamMulai;
                                                    data.JamSelesai = JamSelesai;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="">Silahkan pilih jam</option>
                                                    {
                                                        this.state.DataJam.map((opt, i) => {
                                                            return (<option value={opt.Nama} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Jam</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jam Mulai</label>
                                                <input type="time" className='form-control' id="edtJamMulai" name="JamMulai" value={this.state.Detail.JamMulai || "07:00"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JamMulai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan pilih Jam Mulai</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jam Selesai</label>
                                                <input type="time" className='form-control' id="edtJamSelesai" name="JamSelesai" value={this.state.Detail.JamSelesai || "09:00"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JamSelesai = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan pilih Jam Selesai</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pengawas</label>
                                                <select className='form-select' id="IDGuru" name="IDGuru" value={this.state.Detail.IDGuru || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDGuru = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataGuru) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaGuru = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih Guru</option>
                                                    {
                                                        this.state.DataGuru.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih guru</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kelas</label>
                                                <select className='form-select' id="edtIDKelas" name="IDKelas" value={this.state.Detail.IDKelas || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDKelas = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih kelas</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tahun Ajaran</label>
                                                <select className='form-select' id="edtIDTahunAjaran" name="IDTahunAjaran" value={this.state.Detail.IDTahunAjaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDTahunAjaran = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataTahun.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih tahun ajaran</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pelajaran</label>
                                                <select className='form-select' id="edtIDPelajaran" name="IDPelajaran" value={this.state.Detail.IDPelajaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelajaran = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataMapel) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaPelajaran = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="">Silahkan pilih mata pelajaran</option>
                                                    {
                                                        this.state.DataMapel.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/kurikulum_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus jadwal ujian" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Jadwal Ujian</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
