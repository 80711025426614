import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, host, openFile } from '../Modul';
import RendTables from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';

let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataMurid: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/bk_api", { act: "data pemanggilan ortu", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField, Detail: { ID: "", TTDBK: imgUpload, TTDWaliMurid: imgUpload } });
    }

    async handleModalForm(Detail = { ID: "" }) {
        Detail.TTDBK = Detail.ID != "" ? host.replace("siamik/", "") + Detail.TTDBK : imgUpload;
        Detail.TTDWaliMurid = Detail.ID != "" ? host.replace("siamik/", "") + Detail.TTDWaliMurid : imgUpload;
        console.log(Detail);
        this.setState({ Detail: Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    handleModalCariMurid() {
        openModal("modalCariMurid");
        this.handleCariMurid("");
    }

    async handleCariMurid(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/mastermurid_api", { act: "data master murid", limit: 500, q: val });
            if (sql.status == "sukses") this.setState({ DataMurid: sql.data });
        }, 500);
    }

    async handlePilihMurid(e) {
        let data = e.data;
        let Detail = this.state.Detail;
        Detail.NISN = data.NISN;
        Detail.NamaMurid = data.NAMA;
        Detail.MuridID = data.ID;
        this.setState({ Detail: Detail });
        document.getElementById('btnTutupModalCariMurid').click();

    }

    async handlePilihImage(e, obj, compress) {
        let File = await openFile(e, compress);
        let Gambar = File[0].img;
        let Detail = this.state.Detail;
        Detail[obj] = Gambar;
        this.setState({ Detail: Detail });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Pemanggilan Ortu / Wali</div>
                            <div className="col-md-3 d-flex justify-content-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama / Kode' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/bk_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah pemanggilan ortu" : "edit pemanggilan ortu"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="MuridID" value={this.state.Detail.MuridID} />
                                <input type='hidden' name="NISN" value={this.state.Detail.NISN} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Pemanggilan Ortu / Wali" : "Edit Pemanggilan Ortu / Wali"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className='form-control' id="Tanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Siswa</label>
                                                <div className="input-group">
                                                    <input type="text" className='form-control' id="NamaMurid" name="NamaMurid" value={this.state.Detail.NamaMurid || ""}
                                                        onChange={(e) => {
                                                            let data = this.state.Detail;
                                                            data.NamaMurid = e.target.value;
                                                            this.setState({ Detail: data });
                                                        }}
                                                        onFocus={(e) => this.handleModalCariMurid()}
                                                        readOnly />
                                                    <button type='button' className='btn btn-default' onClick={() => this.handleModalCariMurid()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Siswa</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Yang Hadir</label>
                                                <input type="text" className='form-control' name="YangHadir" value={this.state.Detail.YangHadir || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.YangHadir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan pilih yang hadir</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Hubungan</label>
                                                <input type="text" className='form-control' id="edtHubungan" name="Hubungan" value={this.state.Detail.Hubungan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Hubungan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Hubungan</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Masalah</label>
                                        <textarea className='form-control' id="Masalah" name="Masalah" value={this.state.Detail.Masalah || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Masalah = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Masalah</div>
                                    </div>
                                    <p></p>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>TTD Guru BK</label>
                                                <label className='d-flex justify-content-center align-items-center'>
                                                    <input type='file' accept='image/*' name='TTDBK' className='d-none' onChange={(e) => this.handlePilihImage(e, 'TTDBK', true)} />
                                                    <img src={this.state.Detail.TTDBK} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>TTD Wali Murid</label>
                                                <label className='d-flex justify-content-center align-items-center'>
                                                    <input type='file' accept='image/*' name='TTDWaliMurid' className='d-none' onChange={(e) => this.handlePilihImage(e, 'TTDWaliMurid', true)} />
                                                    <img src={this.state.Detail.TTDWaliMurid} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <input type='hidden' name="ID" value={this.state.Detail.ID} />
                            <input type='hidden' name="act" value="hapus kpi" />
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Murid</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" id="edtCari" onChange={(e) => this.handleCariMurid(e.target.value)} placeholder='Cari Nama / NISN' />
                                <p></p>
                                <RendTables
                                    tbody={this.state.DataMurid}
                                    thead={[
                                        { cap: "NISN", sort: "NISN", type: "str" },
                                        { cap: "NAMA", sort: "NAMA", type: "str" },
                                        { cap: "KELAS", sort: "Kelas", type: "str" },
                                        { cap: "ALAMAT", sort: "ALAMAT", type: "str" },
                                        { cap: "TELP", sort: "TELP", type: "str" },
                                    ]}
                                    group={[{ sort: "Kelas", cap: "Kelas" }]}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMurid'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/bk_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus pemanggilan ortu" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Pemanggilan Ortu/Wali</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalPemanggilanOrtu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
