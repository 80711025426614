import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';
import imgUpload from '../assets/img/img-upload.png';
import DateRangeBox from 'devextreme-react/date-range-box';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            D1: saiki(),
            D2: saiki(),
            DataKelas: [],
            DataMurid: [],
            DataGuru: [],
            DataMapel: []
        };
    }

    async componentDidMount() {
        this.handleMain();
    }

    async handleMain() {
        let sql = await api("controler/masterkelas_api", { act: "data jurnal kelas", q: this.state.q, D1: this.state.D1, D2: this.state.D2 }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/masterkelas_api", { act: "detail jurnal kelas", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataMurid: sql.detail, DataGuru: sql.guru, DataKelas: sql.kelas, DataMapel: sql.mapel });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handlePilihImage(e, obj, compress) {
        let File = await openFile(e, compress);
        let Gambar = File[0].img;
        let Detail = this.state.Detail;
        Detail[obj] = Gambar;
        this.setState({ Detail: Detail });
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    async handleCariMurid(IDKelas) {
        let sql = await api("controler/masterkelas_api", { act: "data master murid", IDKelas: IDKelas }, true);
        if (sql.status == "sukses") this.setState({ DataMurid: sql.data });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-3 main-title">Jurnal Kelas</div>
                            <div className="col-md-9 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="col-md-5 d-flex align-items-end gap-1">
                                    <DateRangeBox
                                        defaultValue={[this.state.D1, this.state.D2]}
                                        onValueChanged={this.onCurrentValueChange}
                                    />
                                    <div className="input-group input-group-sm w-50">
                                        <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                        <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "NamaKelas", cap: "NamaKelas" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah jurnal kelas" : "edit jurnal kelas"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Jurnal Kelas" : "Edit Jurnal Kelas"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className='form-control' id="edtTanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Jam</label>
                                                <input type="text" className='form-control' id="Jam" name="Jam" value={this.state.Detail.Jam || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Jam = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Jam</div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className='form-group'>
                                                <label>Kelas</label>
                                                <input type='hidden' name="NamaKelas" value={this.state.Detail.NamaKelas} />
                                                <select className='form-select' id="edtIDKelas" name="IDKelas" value={this.state.Detail.IDKelas || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDKelas = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataKelas) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaKelas = Nama;
                                                    this.setState({ Detail: data });
                                                    this.handleCariMurid(e.target.value);
                                                }} >
                                                    <option value="">Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Guru</label>
                                                <input type='hidden' name="NamaGuru" value={this.state.Detail.NamaGuru} />
                                                <select className='form-select' id="edtIDGuru" name="IDGuru" value={this.state.Detail.IDGuru || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDGuru = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataGuru) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaGuru = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataGuru.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Pelajaran</label>
                                                <input type='hidden' name="NamaPelajaran" value={this.state.Detail.NamaPelajaran} />
                                                <select className='form-select' id="edtIDPelajaran" name="IDPelajaran" value={this.state.Detail.IDPelajaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelajaran = e.target.value;
                                                    let Nama = "";
                                                    for (let dd of this.state.DataMapel) if (dd.ID == e.target.value) Nama = dd.Nama;
                                                    data.NamaPelajaran = Nama;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    {
                                                        this.state.DataMapel.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih mapel</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Kegiatan</label>
                                        <textarea className='form-control' id="edtKegiatan" name="Kegiatan" value={this.state.Detail.Kegiatan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Kegiatan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Kegiatan</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Catatan</label>
                                        <textarea className='form-control' id="Keterangan" name="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Keterangan</div>
                                    </div>
                                    <input type='hidden' value={JSON.stringify(this.state.DataMurid)} name='Murid' />
                                    <p></p>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Foto</label>
                                                <label className='d-flex justify-content-center align-items-center'>
                                                    <input type='file' accept='image/*' name="Files" className='d-none' onChange={(e) => this.handlePilihImage(e, 'Foto', true)} />
                                                    <img src={this.state.Detail.TTDMurid ? host.replace("/siamik", "") + this.state.Detail.TTDMurid : imgUpload} htmlFor="BackgroundImage" style={{ width: "100%" }} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <p></p>
                                    <div className="table-responsive">
                                        <table className="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Kehadiran</th>
                                                    <th>NISN</th>
                                                    <th>Nama</th>
                                                    <th>Note</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataMurid.map((tr, i) => {
                                                        return (<tr key={i}>
                                                            <td>
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox" role="switch" checked={tr.IsHadir == 1 ? true : false} onChange={(e) => {
                                                                        let data = this.state.DataMurid;
                                                                        data[i].IsHadir = e.target.checked == true ? 1 : 0;
                                                                        this.setState({ DataMurid: data });
                                                                    }} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <input type="hidden" name="IDMurid[]" value={tr.ID} />
                                                                <input type="text" className="form-control" value={tr.NISN} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.NAMA} readOnly />
                                                            </td>
                                                            <td>
                                                                <input type="text" className="form-control" value={tr.Notes} onChange={(e) => {
                                                                    let data = this.state.DataMurid;
                                                                    data[i].Notes = e.target.value;
                                                                    this.setState({ DataMurid: data });
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterkelas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus jurnal kelas" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Jurnal Kelas</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;
