import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki } from '../Modul';
import RendTables from '../component/RendTable';
class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            DataGroup: [],
            Detail: {},
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/masterguru_api", { act: "data master guru", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/masterguru_api", { act: "detail master guru", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Master Guru</div>
                                    <div className="col-md-4 d-flex gap-1">
                                        <div className="input-group gap-2">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Guru' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <RendTables tbody={this.state.Data}
                                    thead={[
                                        { cap: "", sort: "", type: "opsi" },
                                        { cap: "NIP", sort: "NIP" },
                                        { cap: "Nama", sort: "Nama" },
                                        { cap: "Telp", sort: "Telp" },
                                        { cap: "Alamat", sort: "Alamat" },
                                    ]}

                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterguru_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master guru" : "edit master guru"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Guru" : "Edit Guru"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIP</label>
                                                <input type="text" className='form-control' id="edtNIP" name="NIP" value={this.state.Detail.NIP || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIP = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIP</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>NIK</label>
                                                <input type="text" className='form-control' id="edtNIK" name="NIK" value={this.state.Detail.NIK || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NIK = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan NIK</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Depan</label>
                                                <input type="text" className='form-control' name="NamaDepan" value={this.state.Detail.NamaDepan || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NamaDepan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan nama depan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Belakang</label>
                                                <input type="text" className='form-control' name="NamaBelakang" value={this.state.Detail.NamaBelakang || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.NamaBelakang = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan nama belakang</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tempat Lahir</label>
                                                <input type="text" className='form-control' name="TempatLahir" value={this.state.Detail.TempatLahir || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TempatLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tempat lahir</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Lahir</label>
                                                <input type="date" className='form-control' name="TanggalLahir" value={this.state.Detail.TanggalLahir || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalLahir = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan tanggal lahir</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Alamat</label>
                                        <textarea className='form-control' name="Alamat" value={this.state.Detail.Alamat || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Alamat = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan alamat</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Telp</label>
                                        <input type="text" className='form-control' name="Telp" value={this.state.Detail.Telp || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Telp = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan nomor telepon</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input type="email" className='form-control' name="Email" value={this.state.Detail.Email || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Email = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan email</div>
                                    </div>

                                    {this.state.ID == "" &&
                                        <div className='form-group'>
                                            <label>Password</label>
                                            <input type="password" className='form-control' name="Pwd" value={this.state.DetailMurid.Pwd || ""} onChange={(e) => {
                                                let data = this.state.DetailMurid;
                                                data.Pwd = e.target.value;
                                                this.setState({ DetailMurid: data });
                                            }} />
                                            <span className='text-danger'>* Password bawaan adalah tanggal lahir Ex: 05122024</span>
                                        </div>
                                    }

                                    <div className='form-group'>
                                        <label>Pendidikan</label>
                                        <select className='form-select' name="Pendidikan" id="edtPendidikan" value={this.state.Detail.Pendidikan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Pendidikan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="SMA">SMA</option>
                                            <option value="Diploma">Diploma</option>
                                            <option value="Sarjana">Sarjana</option>
                                            <option value="Master">Master</option>
                                            <option value="Doctor">Doctor</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Status Kepegawaian</label>
                                                <select className='form-select' name="StatusKepegawaian" id="edtStatusKepegawaian" value={this.state.Detail.StatusKepegawaian || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.StatusKepegawaian = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} >
                                                    <option value="Tetap">Tetap</option>
                                                    <option value="Honorer">Honorer</option>
                                                    <option value="Magang">Magang</option>
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan status</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Jenis Kelamin</label>
                                                <select type="password" className="form-select" name='JenisKelamin' id="JenisKelamin" value={this.state.Detail.JenisKelamin || "Laki-laki"} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.JenisKelamin = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="Laki-laki">Laki-laki</option>
                                                    <option value="Perempuan">Perempuan</option>
                                                </select>
                                                <div className="invalid-feedback">Silahkan Masukan Jenis Kelamin</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Status</label>
                                        <select className='form-select' name="Status" value={this.state.Detail.Status || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Status = e.target.value;
                                            this.setState({ Detail: data });
                                        }} >
                                            <option value="Aktif">Aktif</option>
                                            <option value="Keluar">Tidak Aktif</option>
                                        </select>
                                        <div className='invalid-feedback'>Silahkan masukan status</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masterguru_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master mapel" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Guru</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus guru ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
