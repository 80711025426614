import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile, host } from '../Modul';
import RendTables from '../component/RendTable';
let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataKelas: [],
            DataSarana: []
        };
    }

    async componentDidMount() {
        let sql = await api("controler/sarpras_api", { act: "data master kelas" });
        if (sql.status == 'sukses') {
            this.setState({ DataKelas: sql.data });
            this.handleMain();
        }
    }

    async handleMain() {
        let sql = await api("controler/sarpras_api", { act: "data sarana kelas", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/sarpras_api", { act: "detail sarana kelas", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    modalCariSarana() {
        openModal("modalSarana");
        this.handleCariSarana("");
    }

    async handleCariSarana(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/sarpras_api", { act: "data master sarana", q: val });
            if (sql.status == "sukses") this.setState({ DataSarana: sql.data });
        }, 500);

    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Daftar Sarana Kelas</div>
                            <div className="col-md-3 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama / Kode' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{cap:"Kelas", sort:"Kelas"}]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah daftar sarana" : "edit daftar sarana"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Daftar Sarpras" : "Edit Daftar Sarpras"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal Penempatan</label>
                                                <input type="date" className="form-control" id="TanggalPenempatan" name="TanggalPenempatan" value={this.state.Detail.TanggalPenempatan || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.TanggalPenempatan = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal Penempatan</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama Sarpras</label>
                                                <div className='input-group'>
                                                    <input type="hidden" name="Kode" value={this.state.Detail.Kode || ""} />
                                                    <input type="hidden" name="IDSarana" value={this.state.Detail.IDSarana || ""} />
                                                    <input type="text" className="form-control" id="edtNamaSarana" name="NamaSarana" value={this.state.Detail.NamaSarana || ""} onFocus={() => this.modalCariSarana()} readOnly />
                                                    <button className='btn btn-default' type='button' onClick={() => this.modalCariSarana()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Kode</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Penempatan Sarana</label>
                                                <input type="hidden" name="LokasiSarana" value={this.state.Detail.LokasiSarana} />
                                                <select className='form-select' name="IDKelas" value={this.state.Detail.IDKelas || null} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDKelas = e.target.value;
                                                    let LokasiSarana = "";
                                                    for (let dd of this.state.DataKelas) if (e.target.value == dd.ID) LokasiSarana = dd.Nama;
                                                    data.LokasiSarana = LokasiSarana;
                                                    this.setState({ Detail: data });
                                                }} required>
                                                    <option value="">Silahkan pilih kelas</option>
                                                    {
                                                        this.state.DataKelas.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan masukan Penempatan Sarana</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Qty</label>
                                                <input type="number" className="form-control" id="edtQty" name="Qty" value={this.state.Detail.Qty || 1} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Qty = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} />
                                                <div className='invalid-feedback'>Silahkan masukan Qty</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Keterangan</label>
                                        <textarea className="form-control" name='Keterangan' id="edtKeterangan" value={this.state.Detail.Keterangan} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >

                <div className="modal fade" id="modalSarana" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Master Sarana</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" onChange={(e) => this.handleCariSarana(e.target.value)} />
                                <p></p>
                                <div className="table-responsive">
                                    <table className="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Kode</th>
                                                <th>Nama</th>
                                                <th>Keterangan</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.DataSarana.map((tr, i) => {
                                                    return (<tr key={i} onClick={(e) => {
                                                        let data = this.state.Detail;
                                                        data.Kode = tr.Kode;
                                                        data.NamaSarana = tr.Nama;
                                                        data.IDSarana = tr.ID;
                                                        this.setState({ Detail: data });
                                                        document.getElementById('btnTutupModalSarana').click();
                                                    }}>
                                                        <td>{tr.Kode}</td>
                                                        <td>{tr.Nama}</td>
                                                        <td>{tr.Keterangan}</td>
                                                    </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalSarana'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/sarpras_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master sarana" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Master Sarpras</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Main;
