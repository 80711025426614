import React, { Fragment } from 'react';
import { api, submitForm, openModal, tanggalIndo, exportData, importData, checkDateFormat, saiki, host } from '../Modul';
import RendTables from '../component/RendTable';

let __typingTimer;

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            NamaMurid: "",
            Murid: {},
            Wali: {},
            DataKelas: [],
            DataPelanggaran: [],
            DataPrestasi: [],
            DataMurid: [],
            DataPemanggilan: []
        };
    }

    handleModalCariMurid() {
        openModal("modalCariMurid");
        this.handleCariMurid("");
    }

    async handleCariMurid(val = "") {
        clearTimeout(__typingTimer);
        __typingTimer = setTimeout(async () => {
            let sql = await api("controler/mastermurid_api", { act: "data master murid", limit: 500, q: val });
            if (sql.status == "sukses") this.setState({ DataMurid: sql.data });
        }, 500);
    }

    async handlePilihMurid(e) {
        let data = e.data;
        let sql = await api("controler/mastermurid_api", { act: "data buku pribadi", ID: data.ID });
        if (sql.status == "sukses") {
            this.setState({ Murid: sql.murid, Wali: sql.wali, DataKelas: sql.kelas, DataPelanggaran: sql.pelanggaran, DataPrestasi: sql.prestasi, DataPemanggilan: sql.pemanggilan });
            document.getElementById('btnTutupModalCariMurid').click();
        }
    }

    render() {
        let { Murid, Wali, DataKelas, DataPelanggaran, DataPrestasi, DataPemanggilan } = this.state;
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-7 main-title">Buku Pribadi</div>
                            <div className="col-md-5 d-flex justify-content-end gap-1">
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" onFocus={() => this.handleModalCariMurid()} placeholder='Cari Data Siswa' value={this.state.NamaMurid} readOnly />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleModalCariMurid()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button bg-success text-light" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                        Data Siswa
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td width="200px">No. Induk Siswa</td>
                                                        <td>:</td>
                                                        <td>{Murid.NISN}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nama Lengkap</td>
                                                        <td>:</td>
                                                        <td>{Murid.NAMA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Jenis Kelamin</td>
                                                        <td>:</td>
                                                        <td>{Murid.JENIS_KELAMIN}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tempat Tanggal Lahir</td>
                                                        <td>:</td>
                                                        <td>{Murid.TEMPAT_LAHIR} - {tanggalIndo(Murid.TANGGAL_LAHIR || saiki())}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agama</td>
                                                        <td>:</td>
                                                        <td>{Murid.AGAMA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Alamat Lengkap</td>
                                                        <td>:</td>
                                                        <td>{Murid.ALAMAT} TELP : {Murid.NO_HANDPHONE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Telp</td>
                                                        <td>:</td>
                                                        <td>{Murid.NO_HANDPHONE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tinggal Dengan</td>
                                                        <td>:</td>
                                                        <td>{Murid.STATUS_ALAMAT}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ke Sekolah Ditempuh dengan</td>
                                                        <td>:</td>
                                                        <td>{Murid.TRANSPORTASI_KE_SEKOLAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Anak Ke</td>
                                                        <td>:</td>
                                                        <td>{Murid.ANAK_KE}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Asal Sekolah</td>
                                                        <td>:</td>
                                                        <td>{Murid.ASAL_SEKOLAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Alamat Sekolah Asal</td>
                                                        <td>:</td>
                                                        <td>{Murid.ALAMAT_SEKOLAH}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button bg-success text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                        Data Orag Tua / Wali
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td width="200px">Nama Ayah</td>
                                                        <td>:</td>
                                                        <td>{Murid.NAMA_AYAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agama</td>
                                                        <td>:</td>
                                                        <td>{Murid.AGAMA_AYAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Suku Bangsa</td>
                                                        <td>:</td>
                                                        <td>Indonesia</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pendidikan Terahir</td>
                                                        <td>:</td>
                                                        <td>{Murid.PENDIDIKAN_AYAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pekerjaan</td>
                                                        <td>:</td>
                                                        <td>{Murid.PEKERJAAN_AYAH}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Alamat Rumah</td>
                                                        <td>:</td>
                                                        <td>{Murid.ALAMAT_WALI} TELP : {Murid.NO_HANDPHONE}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <hr></hr>
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td width="200px">Nama Ibu</td>
                                                        <td>:</td>
                                                        <td>{Murid.NAMA_IBU}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agama</td>
                                                        <td>:</td>
                                                        <td>{Murid.AGAMA_IBU}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Suku Bangsa</td>
                                                        <td>:</td>
                                                        <td>Indonesia</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pendidikan Terahir</td>
                                                        <td>:</td>
                                                        <td>{Murid.PENDIDIKAN_IBU}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pekerjaan</td>
                                                        <td>:</td>
                                                        <td>{Murid.PEKERJAAN_IBU}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Alamat Rumah</td>
                                                        <td>:</td>
                                                        <td>{Murid.ALAMAT_WALI} TELP : {Murid.NO_HANDPHONE}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <hr></hr>
                                            <table className="table table-striped">
                                                <tbody>
                                                    <tr>
                                                        <td width="200px">Nama Wali</td>
                                                        <td>:</td>
                                                        <td>{Murid.NAMA_WALI}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Agama</td>
                                                        <td>:</td>
                                                        <td>{Murid.AGAMA_WALI}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Suku Bangsa</td>
                                                        <td>:</td>
                                                        <td>Indonesia</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pendidikan Terahir</td>
                                                        <td>:</td>
                                                        <td>{Murid.PENDIDIKAN_WALI}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Pekerjaan</td>
                                                        <td>:</td>
                                                        <td>{Murid.PEKERJAAN_WALI}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Alamat Rumah</td>
                                                        <td>:</td>
                                                        <td>{Murid.ALAMAT_WALI} TELP : {Murid.NO_HANDPHONE}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button bg-success text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                        Catatan Pelanggaran Siswa
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Tanggal</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Jenis Pelanggaran</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Penanganan</th>
                                                        <th colSpan={3} style={{ textAlign: "center" }}>Tanda Tangan</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Guru BK</th>
                                                        <th style={{ textAlign: "center" }}>Siswa</th>
                                                        <th style={{ textAlign: "center" }}>Orang Tua</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        DataPelanggaran.length > 0 ?
                                                            DataPelanggaran.map((tr, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{tr.Tanggal}</td>
                                                                    <td>
                                                                        Skor : <b>{tr.Skor}</b>
                                                                        <p></p>
                                                                        {tr.NamaPelanggaran}
                                                                    </td>
                                                                    <td>{tr.Penanganan}</td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDBK} width="200px" height="200px" />
                                                                    </td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDMurid} width="200px" height="200px" />
                                                                    </td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDWaliMurid} width="200px" height="200px" />
                                                                    </td>
                                                                </tr>)
                                                            }) :
                                                            <tr>
                                                                <td colSpan={7}>Tidak ada data pelanggaran</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button bg-success text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ColapsePrestasi" aria-expanded="false" aria-controls="ColapsePrestasi">
                                        Catatan Prestasi Siswa
                                    </button>
                                </h2>
                                <div id="ColapsePrestasi" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Tanggal</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Jenis Prestasi</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Penanganan</th>
                                                        <th colSpan={3} style={{ textAlign: "center" }}>Tanda Tangan</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Guru BK</th>
                                                        <th style={{ textAlign: "center" }}>Siswa</th>
                                                        <th style={{ textAlign: "center" }}>Orang Tua</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        DataPrestasi.length > 0 ?
                                                            DataPrestasi.map((tr, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{tanggalIndo(tr.Tanggal)}</td>
                                                                    <td>
                                                                        Skor : <b>{tr.Skor}</b>
                                                                        <p></p>
                                                                        {tr.NamaPelanggaran}
                                                                    </td>
                                                                    <td>{tr.Penanganan}</td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDBK} width="200px" height="200px" />
                                                                    </td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDMurid} width="200px" height="200px" />
                                                                    </td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDWaliMurid} width="200px" height="200px" />
                                                                    </td>
                                                                </tr>)
                                                            }) :
                                                            <tr>
                                                                <td colSpan={7}>Tidak ada data prestasi</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button bg-success text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ColapsePemanggilanOrtu" aria-expanded="false" aria-controls="ColapsePemanggilanOrtu">
                                        Data Pemanggilan Orang Tua
                                    </button>
                                </h2>
                                <div id="ColapsePemanggilanOrtu" className="accordion-collapse collapse">
                                    <div className="accordion-body">
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Tanggal</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Yang Hadir</th>
                                                        <th rowSpan={2} style={{ textAlign: "center", verticalAlign: "center" }}>Hubungan</th>
                                                        <th colSpan={2} style={{ textAlign: "center" }}>Masalah</th>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ textAlign: "center" }}>Guru BK</th>
                                                        <th style={{ textAlign: "center" }}>Orang Tua</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        DataPemanggilan.length > 0 ?
                                                            DataPemanggilan.map((tr, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{tanggalIndo(tr.Tanggal)}</td>
                                                                    <td>{tr.YangHadir}</td>
                                                                    <td>{tr.Hubungan}</td>
                                                                    <td>{tr.Masalah}</td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDBK} width="200px" height="200px" />
                                                                    </td>
                                                                    <td>
                                                                        <img src={host.replace("siamik/", "") + tr.TTDWaliMurid} width="200px" height="200px" />
                                                                    </td>
                                                                </tr>)
                                                            })
                                                            : <tr>
                                                                <td colSpan={6}>Tidak ada pemanggilan orang tua</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariMurid" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Murid</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <input type="search" className="form-control" id="edtCari" onChange={(e) => this.handleCariMurid(e.target.value)} placeholder='Cari Nama / NISN' />
                                <p></p>
                                <RendTables
                                    tbody={this.state.DataMurid}
                                    thead={[
                                        { cap: "NISN", sort: "NISN", type: "str" },
                                        { cap: "NAMA", sort: "NAMA", type: "str" },
                                        { cap: "KELAS", sort: "Kelas", type: "str" },
                                        { cap: "ALAMAT", sort: "ALAMAT", type: "str" },
                                        { cap: "TELP", sort: "TELP", type: "str" },
                                    ]}
                                    group={[{ sort: "Kelas", cap: "Kelas" }]}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMurid'>Batal</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
