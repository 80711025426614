import React, { Fragment } from 'react';
import { api, submitForm, openModal, tanggalIndo, exportData, importData, checkDateFormat, saiki } from '../Modul';
import RendTables from '../component/RendTable';
import DateRangeBox from 'devextreme-react/date-range-box';
import QRCode from 'qrcode.react';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            D1: saiki(1),
            D2: saiki(),
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/absensi_api", { act: "data absensi murid", q: this.state.q, D1: this.state.D1, D2: this.state.D2 }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    onCurrentValueChange = ({ value: [startDate, endDate] }) => {
        this.setState({ D1: startDate, D2: endDate });
    };

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-7 main-title">Absensi Siswa</div>
                            <div className="col-md-5 d-flex align-items-end gap-1">
                                <DateRangeBox
                                    defaultValue={[this.state.D1, this.state.D2]}
                                    onValueChanged={this.onCurrentValueChange}
                                />
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "Kelas", cap: "Kelas" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-ellipsis-v", fn: (e) => this.handleModalProses(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
