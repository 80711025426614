import React, { Fragment } from 'react';
import { api, submitForm, openModal, saiki, openFile } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
            DataKpi: [],
            DataGuru: []
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/humas_api", { act: "data pelanggaran guru", q: this.state.q }, true);
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = { ID: "" }) {
        let sql = await api("controler/humas_api", { act: "detail pelanggaran guru", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: sql.data || Detail, DataKpi: sql.kpi });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    async handlePilihImage(e, obj, compress) {
        let File = await openFile(e, compress);
        let Gambar = File[0].img;
        let Detail = this.state.Detail;
        Detail[obj] = Gambar;
        this.setState({ Detail: Detail });
    }

    handleModalCariGuru() {
        this.handleCariGuru("");
        openModal("modalCariGuru");
    }

    async handleCariGuru(val) {
        let sql = await api("controler/humas_api", { act: "data master guru", q: val }, true);
        if (sql.status == "sukses") this.setState({ DataGuru: sql.data });
    }

    handlePilihMurid(e) {
        let data = e.data;
        let Detail = this.state.Detail;
        Detail.IDUser = data.ID;
        Detail.Nama = data.Nama;
        Detail.NISN = data.NIP;
        this.setState({ Detail: Detail });
        document.getElementById('btnTutupModalCariGuru').click();
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-9 main-title">Pelanggaran Guru</div>
                            <div className="col-md-3 d-flex justify-content-end align-items-end gap-1">
                                <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                <div className="input-group input-group-sm w-50">
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Nama / Kode' />
                                    <button className="btn btn-sm btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            group={[{ sort: "NIK", cap: "NIK" }]}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="act" value={this.state.Detail.ID == "" ? "tambah pelanggaran guru" : "edit pelanggaran guru"} />
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="JenisUser" value="Guru" />
                                <input type='hidden' name="JenisKpi" value="Pelanggaran" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == "" ? "Tambah Pelanggaran Guru" : "Edit Pelanggaran Guru"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Tanggal</label>
                                                <input type="date" className='form-control' id="edtTanggal" name="Tanggal" value={this.state.Detail.Tanggal || saiki()} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Tanggal = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Tanggal</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Guru</label>
                                                <div className='input-group'>
                                                    <input type='hidden' name="IDUser" value={this.state.Detail.IDUser} />
                                                    <input type='hidden' name="NISN" value={this.state.Detail.NISN} />
                                                    <input type="text" className='form-control' id="edtNama" name="Nama" value={this.state.Detail.Nama || ""} onFocus={(e) => this.handleModalCariGuru()} readOnly />
                                                    <button type="button" className="btn btn-default" id="btnSearchMurid" onClick={(e) => this.handleModalCariGuru()}>
                                                        <i className='fas fa-search'></i>
                                                    </button>
                                                </div>
                                                <div className='invalid-feedback'>Silahkan masukan Guru</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Jenis</label>
                                                <select className='form-select' id="edtJenisKpi" name="IDPelanggaran" value={this.state.Detail.IDPelanggaran || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.IDPelanggaran = e.target.value;
                                                    let Skor = 0;
                                                    for (let dd of this.state.DataKpi) if (dd.ID == e.target.value) Skor = dd.Skor;
                                                    data.Skor = Skor;
                                                    this.setState({ Detail: data });
                                                }} required >
                                                    <option value="">Silahkan pilih Pelanggaran</option>
                                                    {
                                                        this.state.DataKpi.map((opt, i) => {
                                                            return (<option value={opt.ID} key={i}>{opt.Nama}</option>)
                                                        })
                                                    }
                                                </select>
                                                <div className='invalid-feedback'>Silahkan pilih Jenis</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Skor</label>
                                                <input type="number" className='form-control' id="edtSkor" name="Skor" value={this.state.Detail.Skor || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Skor = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Skor</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='form-group'>
                                        <label>Penanganan</label>
                                        <textarea className='form-control' id="Penanganan" name="Penanganan" value={this.state.Detail.Penanganan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Penanganan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Penanganan</div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalCariGuru" tabIndex="-1" aria-labelledby="modalCariGuru" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content shadow">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Cari Guru</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <RendTables
                                    tbody={this.state.DataGuru}
                                    thead={[
                                        { cap: "NIP", sort: "NIP", type: "str" },
                                        { cap: "NIK", sort: "NIK", type: "str" },
                                        { cap: "Nama", sort: "Nama", type: "str" },
                                        { cap: "Telp", sort: "Telp", type: "str" },
                                        { cap: "Email", sort: "Email", type: "str" },
                                        { cap: "Alamat", sort: "Alamat", type: "str" },
                                    ]}
                                    event={{ click: (e) => this.handlePilihMurid(e) }}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariGuru'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/humas_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus pelanggaran guru" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Pelanggaran Guru</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus data ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Main;
